///
/// Relativity by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	#wrapper {
		background-color: _palette(bg);
	}