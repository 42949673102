///
/// Relativity by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Header */

	@include keyframes('reveal-header') {
		0% { top: -4em; opacity: 0; }
		100% { top: 0; opacity: 1; }
	}

	body {
		padding-top: _size(header, default);

		@include breakpoint('<=small') {
			padding-top: _size(header, small);
		}
	}

	#header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: _size(header, default);
		line-height: _size(header, default);
		background-color: _palette(bg);
		box-shadow: 0 0 0.5rem 0 _palette(bg-shadow);
		cursor: default;
		z-index: _misc(z-index-base);

		a {
			display: block;
			height: inherit;
			line-height: inherit;
			border-bottom: 0;
		}

		> .logo {
			position: absolute;
			left: 1rem;

			strong {
				font-family: _font(family-heading);
				font-weight: _font(weight-heading);
				text-transform: uppercase;
				letter-spacing: 0.025rem;
			}
		}

		> nav {
			position: absolute;
			right: 0;

			> ul {
				list-style: none;
				padding: 0 0.75rem 0 0;
				margin: 0;

				> li {
					position: relative;
					display: inline-block;
					margin: 0 0.875rem;
					padding: 0;

					> a {
						overflow: hidden;

						&.icon {
							&:before {
								float: right;
								margin-left: 0.5rem;
								line-height: inherit;
								color: _palette(fg-light);
							}

							&:hover {
								&:before {
									color: inherit;
								}
							}
						}
					}

					> ul {
						display: none;
					}

					&.current {
						&:after {
							content: '';
							display: block;
							position: absolute;
							bottom: 0;
							left: -0.25rem;
							width: calc(100% + 0.5rem);
							height: 0.25rem;
							background-color: _palette(accent1, bg);
							border-radius: _size(border-radius);
						}
					}

					&.active {
						> a {
							color: _palette(accent1, bg);
						}
					}
				}
			}

			@at-root {
				.dropotron {
					background-color: _palette(bg);
					border-radius: _size(border-radius);
					box-shadow: 0 0.075rem 0.75rem 0 _palette(bg-shadow);
					list-style-type: none;
					margin: -0.5rem 0 0 1px;
					min-width: 10rem;
					padding: 0.5rem 0;

					&.level-0 {
						margin-top: 1.125rem;
						margin-left: 0;

						&:before {
							@include vendor('transform', 'rotate(45deg)');
							background-color: _palette(bg);
							box-shadow: -2px -2px 3px -1px rgba(0,0,0,0.1);
							content: '';
							position: absolute;
							top: -0.375rem;
							right: 2rem;
							width: 0.75rem;
							height: 0.75rem;
						}
					}

					li {
						border-top: solid 1px _palette(border);
						padding: 0;
						position: relative;

						&:first-child {
							border-top: none;
						}

						&.active > a {
							color: _palette(accent1, bg);
						}
					}

					a {
						color: _palette(fg);
						padding: 0.5rem 1rem;
						text-decoration: none !important;
						border: 0;

						&:active, &:hover, &:focus {
							color: _palette(accent1, bg);
						}
					}
				}
			}
		}

		&.reveal {
			@include vendor('animation', 'reveal-header 0.5s ease');
		}

		&.alt {
			@include color-typography(accent2);
			@include vendor('animation', 'none');
			box-shadow: none;
			background-color: transparent;
			position: absolute;

			> .logo {
				top: 1rem;
				left: 2rem;
			}

			> nav {
				top: 1rem;
				right: 1rem;

				> ul {
					padding: 0 0.5rem 0 0;

					> li {
						margin: 0 1.25rem;

						&.current {
							&:after {
								bottom: 0.25rem;
							}
						}
					}
				}
			}

			> .navPanelToggle {
				top: 1rem;
				right: 1rem;
			}
		}

		> .navPanelToggle {
			@include vendor('transition', (
				'background-color #{_duration(transition)} ease-in-out',
				'color #{_duration(transition)} ease-in-out',
				'top #{_duration(transition)} ease-in-out',
				'right #{_duration(transition)} ease-in-out'
			));
			@include icon(false, solid);
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			height: inherit;
			line-height: inherit;
			padding: 0 1rem;
			outline: 0;

			&:before {
				content: '\f0c9';
				line-height: inherit;
				margin: 0 0 0 0.625rem;
				float: right;
			}
		}

		@include breakpoint('<=medium') {
			> nav {
				display: none;
			}

			> .navPanelToggle {
				display: block;
			}
		}

		@include breakpoint('<=small') {
			height: _size(header, small);
			line-height: _size(header, small);

			> .logo {
				left: 1rem;

				.extra {
					display: none;
				}
			}
		}

		@include breakpoint('<=xsmall') {
			&.alt {
				> .logo {
					top: 0;
					left: 1rem;
				}

				> .navPanelToggle {
					top: 0;
					right: 0;
				}
			}
		}
	}