@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'external/fontawesome/fontawesome';
@import 'external/fontawesome/brands';
@import 'external/fontawesome/regular';
@import 'external/fontawesome/solid';
@import 'external/font_source_sans';
@import 'external/font_raleway';
@import 'external/image_captions';

/*
	Relativity by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

// Breakpoints.

@include breakpoints((
        xlarge: (1281px, 1680px),
        large: (981px, 1280px),
        medium: (737px, 980px),
        small: (481px, 736px),
        xsmall: (361px, 480px),
        xxsmall: (null, 360px)
));

// Mixins.

@mixin color($p) {
  @include color-typography($p);
  @include color-box($p);
  @include color-button($p);
  @include color-form($p);
  @include color-list($p);
  @include color-table($p);
  @include color-post($p);
}

// Base.

@import 'base/reset';
@import 'base/page';
@import 'base/typography';

// Component.

@import 'components/row';
@import 'components/box';
@import 'components/button';
@import 'components/form';
@import 'components/icon';
@import 'components/image';
@import 'components/list';
@import 'components/actions';
@import 'components/icons';
@import 'components/faces';
@import 'components/table';
@import 'components/post';
@import 'components/main';

// Layout.

@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/banner';
@import 'layout/footer';
@import 'layout/navPanel';

@media all and (max-width: 480px) {
  .post {
    min-height: auto;
  }
  article .image.placeholder {
    display: none;
  }
}

.box.error {
  border-color: _palette(accent1, bg)
}

#header .logo img {
  height: _size(header, default);
  @include breakpoint('<=small') {
    height: _size(header, small);
  }
}

#banner {
  background-image: image-set(
    url('/assets/img/banner.webp') type("image/webp"),
    url('/assets/img/banner.jpg') type("image/jpeg")
  );
}
#wrapper > .main.alt {
    background-image: image-set(
        url('/assets/img/bg.webp') type("image/webp"),
        url('/assets/img/bg.jpg') type("image/jpeg")
    );
}