///
/// Relativity by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			padding: 0 1.25rem 0 0;

			&:last-child {
				padding-right: 0;
			}

			.icon {
				&:before {
					font-size: 1.25rem;
				}
			}
		}
	}