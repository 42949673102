//https://codepen.io/tasiamiller/pen/VwEEoer

.img-container{
  position: relative;
  margin: 0; /* remove figure margins */
  height: 100%;
}

.img-container img{
  display: block;
  /* setting the display to block
  removes the gap under the image */
}

.img-caption{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255,.7);

  padding: 5px 10px;
  font-size: .85rem;
}

@media all and (max-width: 981px) {
  .col-4 .img-caption {
    display: none;
  }
}
