///
/// Relativity by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out, color #{_duration(transition)} ease-in-out');
		border-radius: _size(border-radius);
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		font-size: 0.75rem;
		height: _size(element-height);
		line-height: _size(element-height) + 0.075rem;
		padding: 0 2.5rem;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
		text-transform: uppercase;
		letter-spacing: 0.025rem;

		&.next {
			@include icon(false, solid);

			&:before {
				content: '\f30b';
				float: right;
				line-height: inherit;
				margin: 0 -0.25rem 0 0.5rem;
			}
		}

		&.icon {
			&:before {
				font-size: 0.7rem;
				margin-right: 0.5rem;
				margin-left: -0.25rem;
			}
		}

		&.fit {
			width: 100%;
		}

		&.small {
			font-size: 0.6rem;
			height: (_size(element-height) * 0.75);
			line-height: ((_size(element-height) + 0.075rem) * 0.75);
			padding: 0 1.5rem;
		}

		&.large {
			font-size: 1rem;
			height: (_size(element-height) * 1.25);
			line-height: ((_size(element-height) + 0.075rem) * 1.25);
			padding: 0 2.5rem;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}

	@mixin color-button($p: null) {
		$highlight: _palette($p, highlight);

		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: _palette($highlight, bg);
			color: _palette($highlight, fg-bold) !important;

			&:hover {
				background-color: lighten(_palette($highlight, bg), 5);
				color: _palette($highlight, fg-bold) !important;
			}

			&:active {
				background-color: darken(_palette($highlight, bg), 5);
			}

			&.alt {
				background-color: transparent;
				box-shadow: inset 0 0 0 2px _palette($p, border);
				color: _palette($p, fg-bold) !important;

				&:hover {
					background-color: _palette($p, border-bg);
				}

				&:active {
					background-color: _palette($p, border2-bg);
				}

				&.icon {
					&:before {
						color: _palette($p, fg-light);
					}
				}
			}
		}
	}

	@include color-button;