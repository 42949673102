///
/// Relativity by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Image */

	.image {
		display: inline-block;
		position: relative;
		border-radius: _size(border-radius);
		border: 0;

		&[data-position] {
			img {
				@include vendor('object-fit', 'cover');
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		&[data-position="top left"] {
			img {
				@include vendor('object-position', 'top left');
			}
		}

		&[data-position="top"] {
			img {
				@include vendor('object-position', 'top');
			}
		}

		&[data-position="top right"] {
			img {
				@include vendor('object-position', 'top right');
			}
		}

		&[data-position="right"] {
			img {
				@include vendor('object-position', 'right');
			}
		}

		&[data-position="bottom right"] {
			img {
				@include vendor('object-position', 'bottom right');
			}
		}

		&[data-position="bottom"] {
			img {
				@include vendor('object-position', 'bottom');
			}
		}

		&[data-position="bottom left"] {
			img {
				@include vendor('object-position', 'bottom left');
			}
		}

		&[data-position="left"] {
			img {
				@include vendor('object-position', 'left');
			}
		}

		&[data-position="center"] {
			img {
				@include vendor('object-position', 'center');
			}
		}

		&[data-position="25% 25%"] {
			img {
				@include vendor('object-position', '25% 25%');
			}
		}

		&[data-position="75% 25%"] {
			img {
				@include vendor('object-position', '75% 25%');
			}
		}

		&[data-position="75% 75%"] {
			img {
				@include vendor('object-position', '75% 75%');
			}
		}

		&[data-position="25% 75%"] {
			img {
				@include vendor('object-position', '25% 75%');
			}
		}

		img {
			display: block;
			border-radius: _size(border-radius);
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 1.5rem 1rem 0;
			top: 0.25rem;
		}

		&.right {
			float: right;
			margin: 0 0 1rem 1.5rem;
			top: 0.25rem;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}

	a.image {
		overflow: hidden;

		img {
			@include vendor('transition', 'transform #{_duration(transition)} ease');
		}

		&:hover {
			img {
				@include vendor('transform', 'scale(1.075)');
			}
		}
	}